import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import Img1 from '../../assets/images/okoli6.webp';
import StezkaBecva from '../../assets/images/stezkabecvaplakat.webp'

const Entertainment = () => {
  const [modalOpen, setModalOpen] = useState(false);


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Img1} imageAlt1={'Fotka hradu Helfštýn'}
            heading={"Rekreace a zábava"}
            />
        <PageContainer>
          <section>
            <h2>Rekreace - lázně Teplice nad Bečvou a okolí</h2><br/>
            <div className='div-wrapper-left'>
              <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/SyDZakcoR_A?si=PXmZRcsGvp8TQ46G" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <h5><strong>Zbrašovské aragonitové jeskyně</strong></h5>
            <p>
              Jedinečný jeskynní systém evropského významu vzniklý současným působením atmosférických a teplých minerálních vod vystupujících z velkých hloubek ve vápencích. Unikátní výzdobou jeskyní je minerál ARAGONIT připomínající koblihy. Jsou nejteplejšími jeskyněmi v celé České republice s celoroční stálou teplotou 14°C. Byly objeveny v roce 1912.<br/>
              Více informací naleznete <a href="https://zbrasovske.caves.cz/" target='_blank' rel="noreferrer">zde</a>.
            </p>
            
            <hr />

            <h5><strong>Hranická propast</strong></h5>
            <p>
              Hranická propast je nejhlubší českou i středoevropskou propastí (404 m), jejíž suchá část, do níž lze nahlédnout má hloubku 69,5 m.V budově teplického nádraží se nachází informační centrum Hranické propasti, kde se dozvíte mnoho zajímavostí o zdejším kraji a jeho přírodních krásách<br/>
              Více informací naleznete <a href="https://hranickapropast.cz/" target='_blank' rel="noreferrer">zde</a>.
            </p>
            
            <hr />

            <h5><strong>Hrad Helfštýn</strong></h5>
            <p>Hrad uprostřed zalesněných kopců nad půvabným údolím řeky Bečvy nabízí poučení i zábavu. Dnešní Helfštýn má charakter rozsáhlé pevnosti se šesti branami, řadou budov a valů z 18. století. Světovou popularitu hrad získal díky Hefaistonu - každoročnímu mezinárodnímu setkání uměleckých kovářů, které se vždy koná poslední srpnový víkend. Rozsáhlý hradní areál se stává dějištěm historického šermu, divadelní, hudební i taneční produkce. Podívejte se na krásný hrad, který za dob svého mládí pomáhali opravovat i majitelé penzionu :-)<br/>
            Více informací naleznete <a href="https://helfstyn.cz/" target='_blank' rel="noreferrer">zde</a>.
            </p>

            <hr />

            <h5><strong>Lanové bludiště</strong></h5>
            <p>
              Více informací naleznete <a href="http://www.lanovebludiste.cz/" target='_blank' rel="noreferrer">zde</a>.
            </p>

            <hr />
            <h5><strong>Další zajímavá města a místa</strong></h5>
            <ul>
              <li>Hranice (zámek, synagoga, radniční věž s vyhlídkou, prohlídky s průvodcem,) </li>
              <li>Olomouc (nádherné historické centrum),</li>
              <li>Rožnov p.Radhoštěm (skanzen)</li>
              <li>Kroměříž (Arcibiskupský zámek, Květná zahrada),</li>
              <li>Lipník nad Bečvou (kovářské město, střešní zahrada),</li>
              <li>Štramberk (pověstná Trúba)</li>
              <li>zřícenina Puchart, Svrčov (hrad ze 14.stol. měl sloužit k ochraně statků olomouckého biskupa. Rytíři ze Svrčova drželi hrad až do konce 15.stol., kdy jej opustili a nechali zpustnout</li>
              <li>Hrad Starý Jičín, Hukvaldy</li>
              <li>větrné mlýny, Jezernické viadukty, železniční tunel ve Slavíči</li>
              <li>Valšovická jezírka (4,5 km) Je soustava tří větších vodních nádrží a čtyř malých jezírek na potoku Krkavec v blízkosti obce Valšovice.</li>
              <li>pěší výlety - hrad Helfštýn (12km), Hranická propast (1,5 km), Valšovická jezírka (5 km)</li>
            </ul>
          </section>

          <section>
            <h2>Cyklistika</h2>
            <div className='small-image-right-wrapper'>
              <div className='copy-container'>
                <p>Bohatý výběr cyklotras  - Po stopách větrné a vodní energie (po dohodě možnost zapůjčení kol). K dispozici cyklomapy i turistické mapy.</p>
                <p>
                  Pro cyklisty je připraven posilující místní cyklobus na trase Hranice n.M. - Makov. Máte-li zájem se podívat o kus dále a sjet si krásnou cyklostezku Bečva, ať už část rožnovskou nebo vsetínskou, zkuste tuto alternativu. Určitě se vám bude líbit. Pro větší skupiny lidí možno domluvit autobus na jiný termín či jinou trasu - vše na telefonu v Turistickém informačním centru: 581 607 479 nebo na oficiálních stránkách Cyklostezky Bečvy na <a href="https://www.cyklostezkabecva.cz/" target='_blank' rel="noreferrer">www.cyklostezkabecva.cz</a>. Můžete se také podívat na jejich <a href="https://www.facebook.com/cyklostezkabecva" target='_blank' rel="noreferrer">facebookové stránky</a>, kde naleznete jakékoliv aktuální informace. 
                </p>
                <p>Nechcete si s sebou brát kola? Využijte půjčovnu kol a koloběžek a vydejte se po nebo proti proudu Bečvy, nebo po stopách využívání <a href="https://www.kudyznudy.cz/aktivity/po-stopach-vyuzivani-vodni-a-vetrne-energie" target='_blank' rel="noreferrer">vodní a větrné energie</a>. Koloběžku využijete pohodlně i v městském provozu. K dispozici je 15 kol, horských i krosových, a 15 koloběžek, na asfalt i do lehkého terénu.<br/><a href="https://infocentrum-hranice.cz/sluzby/pujcovny-sportovnich-potreb/" target='_blank' rel="noreferrer">https://infocentrum-hranice.cz/sluzby/pujcovny-sportovnich-potreb/</a></p>
              </div>
              <div onClick={() => openModal()}>
                <img src={StezkaBecva} alt="Plakat na terminy pro Cyklobusem na Becvu" />
              </div>
                {/* <a href="https://www.facebook.com/mic.hranice/posts/pfbid037L5BPEbewfuhwLepDEovsDeFEgVTJyy6z9PZoXSLdZJuAaXeQe1EUy61UoCHhP8vl">
                  
                </a> */}

              
            </div>
            {modalOpen && (
                <div className="modal" onClick={closeModal}>
                  <div className="modal-content-photogallery" onClick={(e) => e.stopPropagation()}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div >
                      <img src={StezkaBecva} alt={'Plakat na terminy pro Cyklobusem na Becvu'} className="modal-image" />
                    </div>
                  </div>
                </div>
              )}
          </section>
          
          <section>
            <h2>Kultura</h2>
            <p>Pokud si stále nejste jistí, co podnikout během vašeho pobytu a rádi byste do divadla či na koncert, můžete se podívat na akce, které momentálně probíhají na <a href="https://kultura-hranice.cz/akce/" target='_blank' rel="noreferrer">https://kultura-hranice.cz/akce/</a> nebo na <a href="https://www.karnolahranice.cz/" target='_blank' rel="noreferrer">www.karnolahranice.cz</a> a jejich <a href="https://www.facebook.com/KarnolaHranice/" target='_blank' rel="noreferrer">facebookové stránky</a>.</p>
            
            <hr/>

            <h5><strong>Další tipy</strong></h5>
            <ul>
              <li>V dosahu tenisové kurty (antuka i umělý povrch), minigolf, golf (<a href="https://www.golf-radikov.cz/" target='_blank' rel="noreferrer">www.golf-radikov.cz</a>), squash, bowling.</li>
              <li>Aquapark (<a href="https://plovarna-hranice.cz/" target='_blank' rel="noreferrer">www.aquaparkplovarnahranice.cz</a>) - pro ubytované výhodnější vstupné, přírodní koupání, polárium, lázeňské procedury.</li>
              <li>V okolí vyhlídkové lety, jízda na koni.</li>
              <li>Dětská hřiště s prolézačkami na výletišti (cca 600 m) nebo u kiosku v centru obce.</li>
              <li>Chcete se dozvědět jak probíhalo tříleté bádání o Hranickém krasu? Vyražte na <a href="https://www.regionhranicko.cz/mas-hranicko/aktuality/Trilete-badani-o-Hranickem-krasu-zavrseno-Prohlednete-si-prezentace-z-konference-a-vyrazte-na-vystavu-1455" target='_blank' rel="noreferrer">výstavu Hranice poznání</a> do hranického městského muzea.</li>
            </ul>
          </section>

        </PageContainer>
        <Footer />
    </>
  )
}

export default Entertainment