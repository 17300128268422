import styled from "styled-components";
import { mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const IconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:30px 0;

    a:hover {
        transition: 0.3s;
        transform: scale(1.2)
    }

    img {
        max-width: 180px;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        flex-direction: row;
        margin: 50px 30px;
        justify-content: start;

        img {
            max-width: 200px;
            margin-right: 70px;
        }
    }

`