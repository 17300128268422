export const navLinks = {
    header: [
        {
            label: 'Ubytování',
            href: '/ubytovani'
        },
        {
            label: 'Wellness a služby',
            href: '/sluzby',
        },
        {
            label: 'Fotogalerie',
            href: '/fotogalerie'
        },
        {
            label: 'Rekreace a zábava',
            href: '/rekreacezabava'
        },
        {
            label: 'Kontakty',
            href: '/kontakty'
        },
        {
            label: 'Ceník',
            href: '/cenik'
        },
        {
            label: 'Zajímavé odkazy',
            href: '/zajimaveodkazy'
        }
    ],
    footer: [
        {
            label: 'Hlavní stránka',
            href: '/'
        },
        {
            label: 'Ubytování',
            href: '/ubytovani'
        },
        {
            label: 'Wellness a služby',
            href: '/sluzby',
        },
        {
            label: 'Fotogalerie',
            href: '/fotogalerie'
        },
        {
            label: 'Rekreace a zábava',
            href: '/rekreacezabava'
        },
        {
            label: 'Kontakty',
            href: '/kontakty'
        },
        {
            label: 'Ceník',
            href: '/cenik'
        },
        {
            label: 'Zajímavé odkazy',
            href: '/zajimaveodkazy'
        },
        {
            label: 'Facebook',
            href: 'https://www.facebook.com/Ostravanka.TeplicenadBecvou/',
            target: '_blank',
            rel: 'noreferrer'
        }
    ]
}