import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import { FaHome, FaEnvelope, FaSearchLocation } from 'react-icons/fa';
import Img1 from '../../assets/images/hero.png';


const Contact = () => {
  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Img1} imageAlt1={'Fotka penzionu Ostravanka'}
            heading={"Kontakty"}
            />
        <PageContainer>
          <section>
            <div className='div-wrapper'>
              <div>
              <h5><FaEnvelope /><strong>Kontaktní údaje</strong></h5>
              <p>Blanka Hlaváčková, (Ing. Pavel Hlaváček)<br/>
              Tel: <a href="tel:+420 581 606 231">+420 581 606 231</a><br/>
              Mobil: <a href="tel:+420 723 963 905">+420 723 963 905</a><br/>
              E-mail: <a href="mailto:penzion@ostravanka.com">penzion@ostravanka.com</a>
              </p>
              <p>IČ: 28571649<br/>
              OR: KS v Ostravě, odd.C, vložka č. 43775
              </p>
              </div>
              <div>
                <h5><FaHome /><strong>Adresa</strong></h5>
                <p>Penzion Ostravanka<br/>
                Teplice nad Bečvou 56<br/>
                753 01 Hranice<br/>
                GPS: 49°31'52.586"N, 17°44'29.148"E
                </p>
              </div>
              <div>
                <h5><FaSearchLocation /><strong>Najděte nás</strong></h5>
                <iframe title='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2589.5224089493113!2d17.738935276920596!3d49.53129155311714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713b9654f9bf489%3A0x7acc5b05c8a78d37!2sPenzion%20Ostravanka!5e0!3m2!1sen!2suk!4v1697800168874!5m2!1sen!2suk" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </section>
        </PageContainer>
        <Footer />
    </>
  )
}

export default Contact