import './fonts.css';


export const colors = {
    green: '#6B9455',
    lightGreen: '#9bcf8e',
    red: '#FD6552',
    pink: '#FF978B',
    lightPink: '#FFF2F1',
    white: '#FFFFFF',
    black: '#000000',
}

export const mediaQueriesSizes = {
    desktopNav:   1260,
    xxxl:         1900,
    xxl:         1600,
    xl:         1169,
    lg:         900,
    md:         700,
    sm:         500,
};

export const typography = {
    global: {
        fontFamilyHeading: 'Ebrima',
        fontFamilyCopy: 'Open Sans'
    },
    h1: {
        fontWeight: '600',
        fontSizeDesktop: '52px',
        fontSizeMobile: '38px'
    },
    h2: {
        fontWeight: '600',
        fontSizeDesktop: '42px',
        fontSizeMobile: '34px',
    },
    h3: {
        fontWeight: '500',
        fontSizeDesktop: '38px',
        fontSizeMobile: '30px',
    },
    h4: {
        fontWeight: '400',
        fontSizeDesktop: '30px',
        fontSizeMobile: '26px',
    },
    h5: {
        fontWeight: '300',
        fontSizeDesktop: '24px',
        fontSizeMobile: '22px',
    },
    p: {
        fontWeight: '300',
        fontSizeDesktop: '18px',
        fontSizeMobile: '16px',
    },
    a: {
        fontWeight: '600',
        fontSizeDesktop: '18px',
        fontSizeMobile: '16px',
    },
    li: {
        fontWeight: '600',
        fontSizeDesktop: '18px',
        fontSizeMobile: '16px',
    },
    button: {
        fontWeight: '600',
        fontSizeDesktop: '20px',
        fontSizeMobile: '18px',
    }
    
}
