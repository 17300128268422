import Pokoj1 from '../../assets/images/pokoj-1.webp';
import Pokoj2 from '../../assets/images/pokoj-11.webp';
import Pokoj3 from '../../assets/images/pokoj-10.webp';
import Pokoj4 from '../../assets/images/pokoj-8.webp';
import Pokoj5 from '../../assets/images/pokoj-7.webp';
import Pokoj6 from '../../assets/images/pokoj-9.webp';
import Pokoj9 from '../../assets/images/pokoj-15.webp';
import Pokoj10 from '../../assets/images/pokoj-16.webp';

import Recepce1 from '../../assets/images/recepce1.webp';
import Recepce2 from '../../assets/images/jidelna-1.webp';
import Recepce3 from '../../assets/images/recepce.webp';
import Recepce4 from '../../assets/images/jidelna-2.webp';
import Recepce5 from '../../assets/images/kuchynka-2.webp';
import Recepce6 from '../../assets/images/zahrada5.webp';
import Zahrada1 from '../../assets/images/zahrada6.webp';
import Zahrada2 from '../../assets/images/kuchynka-1.webp';
import Zahrada3 from '../../assets/images/zahrada12.webp';

import Wellness1 from '../../assets/images/wellness-vstup.webp';
import Wellness2 from '../../assets/images/sauna.webp';
import Wellness3 from '../../assets/images/IMG_7812.webp';
import Wellness5 from '../../assets/images/IMG_7814.webp';
import Wellness6 from '../../assets/images/IMG_7822.webp';
import Wellness7 from '../../assets/images/IMG_7823.webp';
import Wellness8 from '../../assets/images/IMG_7825.webp';
import Wellness9 from '../../assets/images/IMG_7828.webp';
import Wellness10 from '../../assets/images/IMG_7829.webp';

import Okoli1 from '../../assets/images/okoli1.webp';
import Okoli2 from '../../assets/images/okoli2.webp';
import Okoli3 from '../../assets/images/okoli5.webp';
import Okoli4 from '../../assets/images/okoli7.webp';
import Okoli5 from '../../assets/images/okoli11.webp';
import Okoli6 from '../../assets/images/okoli6.webp';

export const recepce = [
  {
    src: Recepce1,
    alt: 'Recepce s prostorem navozující kavárnu'
  },
  {
    src: Recepce3,
    alt: 'Recepční pult, ozdoby, růžové závěsy'
  },
  {
    src: Recepce2,
    alt: 'Společné zázemí se stoly na jídlo.'
  },
  {
    src: Recepce4,
    alt: 'Společné zázemí se záběrem na stoly a gauč a hrací kout'
  },
  {
    src: Recepce5,
    alt: 'Společná kuchyňka se sporákem a troubou, dřezem, toustovačem, noži a dalšími kuchyňskými doplňky'
  },
  {
    src: Zahrada2,
    alt: 'Společná kuchyňka se sporákem a troubou, dřezem, toustovačem, noži a dalšími kuchyňskými doplňky'
  },
  {
    src: Recepce6,
    alt: 'Zahrada s posezením'
  },
  {
    src: Zahrada3,
    alt: 'Zahrada s posezením'
  },
  {
    src: Zahrada1,
    alt: 'Lavička opřená o zeď penzionu Ostravanka s výhledem do upravené zahrady se záhony.'
  }
]

export const pokoje = [
  {
    src: Pokoj5,
    alt: ''
  },
  {
    src: Pokoj4,
    alt: ''
  },
  {
    src: Pokoj6,
    alt: ''
  },
  {
    src: Pokoj3,
    alt: ''
  },
  {
    src: Pokoj1,
    alt: ''
  },
  {
    src: Pokoj9,
    alt: ''
  },
  {
    src: Pokoj10,
    alt: ''
  },
  {
    src: Pokoj2,
    alt: ''
  },
]

export const okoli = [
    {
        src: Okoli6,
        alt: 'Hrad Helfštýn'
    },
    {
        src: Okoli2,
        alt: 'Osvětlené Hranice na Moravě za pozdního večera'
    },
    {
        src: Okoli1,
        alt: 'Řeka Teplá a okolí'
    },
    {
        src: Okoli4,
        alt: ''
    },
    {
        src: Okoli3,
        alt: 'Větrný mlýn'
    },
    {
        src: Okoli5,
        alt: 'Rozhledna'
    }
]

export const wellness = [
    {
        src: Wellness5,
        alt: 'Privátní wellnes se záběrem na hydromasážní vanu, finskou saunu a relaxační posezení v příjemném nastavitelném osvětlení.'
    },
    {
        src: Wellness9,
        alt: 'Privátní wellnes se záběrem na hydromasážní vanu, finskou saunu a relaxační posezení v příjemném nastavitelném osvětlení.'
    },
    {
        src: Wellness10,
        alt: 'Solná lampa s relaxační dekorací na stolku'
    },
    {
        src: Wellness2,
        alt: 'Sauna zevnitř'
    },
    {
        src: Wellness6,
        alt: 'Privátní wellnes se záběrem na relaxační posezení v příjemném nastavitelném osvětlení.'
    },
    {
        src: Wellness8,
        alt: 'Privátní wellnes se záběrem na relaxační posezení v příjemném nastavitelném osvětlení.'
    },
    {
        src: Wellness7,
        alt: 'Privátní wellnes se záběrem na hydromasážní vanu a finskou saunu v příjemném nastavitelném osvětlení.'
    },
    {
        src: Wellness3,
        alt: 'Privátní wellnes se záběrem na relaxační posezení v příjemném nastavitelném osvětlení.'
    },
    {
        src: Wellness1,
        alt: 'Vstupní cedule na dveřích do privátního wellnessu s nápisem Relax Hydrotherapy'
    }
]