import styled from "styled-components";
import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";


export const FormContainer = styled.div`
    padding: 20px;
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 90%;
    margin-top: 1.5rem;
    border: 1px solid lightgrey;
    border-radius: 15px;
    background-color: ${colors.white};
    align-self: center;
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 10px 50px 50px;
        width: 80%;
    }
`