import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const TableContainer = styled.table`
    border: 1px solid ${colors.lightPink};
    border-spacing: 0;
    margin: 30px 0;

    th {
        text-align: left;
        min-height: 30px;
        color: ${colors.white};
        background-color: ${colors.pink};
        padding: 10px;
    }

    td {
        padding: 10px;
        line-height: 1.6;

        img {
            height: 160px;
            max-width: 190px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    tr:nth-child(odd) {
        background-color: ${colors.lightPink};
    }

    td:nth-child(odd) {
        border-right: 1px solid ${colors.white};
        border-left: 1px solid ${colors.white};
    }

    td:nth-child(even) {
        border-right: 1px solid ${colors.lightPink};
        border-left: 1px solid ${colors.lightPink};
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.p.fontSizeDesktop};
    }
`