import React from 'react';
import Button from '../Button';
import Icon1 from '../../assets/images/ahr-logo.webp';
import Icon3 from '../../assets/images/Penzion Superior 2-1.webp';
import Icon6 from '../../assets/images/icon6.png';
import { Container, NavWrapper, List, IconsWrapper, IframeWrapper } from './styles';
import { navLinks } from '../../data/navLinks';

const Footer = () => {

  return (
    <Container>
      <Button className={'button'} label={'Rezervace ubytování'} href={'/rezervace'}/>
      <NavWrapper>
        <List>
          {navLinks.footer.map((link, key) => {
            return (
              <li key={key}><a className={window.location.pathname === link.href ? 'active' : ''} href={link.href} target={link?.target} rel={link?.rel} >{link.label}</a></li>
            )
          })}
        </List>
      </NavWrapper>
      <hr />
      <IconsWrapper>
        <img src={Icon1} alt="Logo Asociace hotelů a restaurací České Republiky" />
        <img src={Icon3} alt="Logo HOTELSTARS.CZ" />
        <a href="https://www.ekatalog.cz/firma/404560-penzion-ostravanka-sro/" target="_blank" rel="noreferrer">
          <img src={Icon6} alt="Logo Ověřená firma"/>
        </a>
        <a href="https://www.kudyznudy.cz/?utm_source=kzn&utm_medium=partneri_kzn&utm_campaign=banner" target="_blank" rel="noreferrer">
          <img src="https://www.kudyznudy.cz/getmedia/e258ea1e-6a92-4443-940f-fdafe8da106e/1012102023-online-bannery-hq-180x150.jpg.aspx" width="180"  height="150" alt="Kudyznudy.cz - nejlepší začátek výletu" />
        </a>
         <IframeWrapper> 
          <div className='container-1'> 
            <span>Naše hodnocení na </span> 
            <a href="http://www.najisto.cz" target="_blank" rel="noreferrer">najisto.cz</a> 
          </div> 
          <iframe title='Najisto' className="iframeWidget" width="288" height="266" frameborder="0" scrolling="no" src="https://najisto.centrum.cz/widget.php?fid=3062963&amp;v=big"></iframe> 
          <div className='container-2'></div> 
        </IframeWrapper> 
      </IconsWrapper>
    </Container>
  )
}

export default Footer