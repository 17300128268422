import React from 'react';
import Button from '../Button';
import { ImgContainer } from './styles';

const HeroBlock = ({ imageSrc1, imageSrc2, imageSrc3, imageSrc4, imageAlt1, imageAlt2, imageAlt3, imageAlt4, heading }) => {
    return (
        <ImgContainer>
            <div className='hero'>
                <img src={imageSrc1} alt={imageAlt1}/>
            </div>
            <div className='side-imgs'>
                <img src={imageSrc2} alt={imageAlt2} />
                <img src={imageSrc3} alt={imageAlt3} />
                <img src={imageSrc4} alt={imageAlt4} />
            </div>
            <div className='button-container'>
                <h1>{heading}</h1>
                <p>Rodinný penzion s dlouhou tradicí i historií v klidné lázeňské obci Teplice nad Bečvou</p>
                <Button className={'button'} label={'Rezervace ubytování'} href={'/rezervace'}/>
            </div>
        </ImgContainer>
    )
}

export default HeroBlock