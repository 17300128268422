import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const ContentContainer = styled.div`
    margin-top: -5px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-areas:
    "contentCopy1"
    "contentImages1"
    "contentCopy2"
    "contentImages2"
    "contentCopy3"
    "contentImages3"; 
    
    
    p {
        font-family: ${typography.global.fontFamilyCopy};
        font-size: 16px;
        line-height: 20px;
        max-width: 90%;
        justify-self: center;
    }

    h2 {
        font-family: ${typography.global.fontFamilyHeading};
        color: ${colors.red};
        width: 90%;
        margin-bottom: 0;
    }

    div {
        display: flex;
        height: 100%;
        width: 100%;
    }

    .content-copy-1 {
        grid-area: contentCopy1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content-images-1 {
        grid-area: contentImages1;

        div {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .content-copy-2 {
        grid-area: contentCopy2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content-images-2 {
        grid-area: contentImages2;

        div {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .content-copy-3 {
        grid-area: contentCopy3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            text-align: left;
        }
    }

    .content-images-3 {
        grid-area: contentImages3;

        div {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }


    @media (min-width: ${mediaQueriesSizes.lg}px) {
        grid-template-areas:
        "contentImages1 contentCopy1"
        "contentCopy2 contentImages2"
        "contentImages3 contentCopy3";
        grid-template-rows: 250px 250px 250px;
        grid-template-columns: 50% 50%;

        h2 {
            width: 80%;
        }

        p {
            max-width: 80%;
        }
    }
    

`