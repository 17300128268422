import React from 'react';
import Logo from '../../assets/images/logo.png'

import { ThankYouHeader, Container } from './styles';

const ThankYou = () => {
  return (
    <>
      <ThankYouHeader>
        <a href="/"><img src={Logo} alt="Penzion Ostravanka logo" /></a>
        <h4><strong>Penzion Ostravanka</strong></h4>
      </ThankYouHeader>
      <Container>
          <h2>Děkujeme za Vaši objednávku</h2>
          <div>
            <p>
              Vaše objednávka byla úspěšně odeslána.<br/>
              Zkontrolujte prosím svůj email.<br/><br/>
              Pracujeme na tom, aby byla v co nekratším termínu vyřízena.<br/>
              Pokud nechcete čekat na odpověď, nejlépe bude zavolat na kontaktní telefony:<br/>
              <a href="tel:723 963 905"> 723 963 905</a> nebo <a href="tel:581 606 231">581 606 231</a><br/><br/>
            </p>
            <a className='home-page-link' href="/">Zpět na domovskou stránku</a>
          </div>
          <h4><strong><i>Těšíme se na Vaši návštěvu!</i></strong></h4>
      </Container>
    </>
  )
}

export default ThankYou