import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    section {
        display: flex;
        flex-direction: column;
        padding: 40px 10px;

        &:nth-child(even) {
            background: ${colors.lightPink};
        }

        &.background {
            background: ${colors.lightPink};
        }

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding: 50px 100px;
        }

        .div-wrapper {
            display: flex;
            flex-direction: column;
            margin: 20px 0;
            
            div:first-child {
                margin-top: 0;
            }

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                flex-direction: row;
                justify-content: space-around;
            }

            div {
                background-color: ${colors.white};
                margin-top: 30px;
                display: flex;
                flex-direction: column;

                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    margin-top: 0;
                }

                h5, p {
                    text-align: center;
                    width: 100%!important;
                    justify-content: center;
                    align-items: center;
                }

                iframe {
                    border: 0;
                    width: 100%:
                    height: auto;
                    margin-top: 10px;
                }
            }
        }

        .div-wrapper-left {
            iframe {
                width: 100%;
                max-width: 661px;
                height: 372px;
                margin-bottom: 30px;
            }
        }

        .div-wrapper-grid {
            padding:0 20px;
            display: grid;
            grid-template-areas: 
            "kitchen" 
            "comunal"
            "other"
            "heading";

            

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                padding: 20px;
                grid-template-areas: 
                "kitchen comunal other"
                "heading heading heading";
                grid-row-gap: 40px;
                grid-column-gap: 40px;
            }

            div {
                background-color: ${colors.white};
                border-radius: 10px;
                padding: 30px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                @media (min-width: ${mediaQueriesSizes.lg}px) {
                    margin-top: 0;
                }

                h5, p {
                    text-align: center;
                    width: 100%!important;
                }

                ul {
                    padding-left: 20px;
                }
            }

            h4 {
                grid-area: heading;
                text-align: center;
                margin: 20px 0;
            }

            #kitchen {
                grid-area: kitchen;
            }

            #comunal {
                grid-area: comunal;
            }

            #other {
                grid-area: other;
            }
        }

        .image-right-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: space-between;

            p {
                width: 100%;
                margin-bottom: 20px;
            }
            img {
                max-width: 100%;
                margin-bottom: 5px;

            }

            @media (min-width: ${mediaQueriesSizes.md}px) {
                flex-direction: row;

                p {
                width: 60%;
                padding-right: 40px;
                }
                img {
                    width: 300px;
                    margin-left: 5px;
                    margin-bottom: -40px;

                }
            }

            @media (min-width: ${mediaQueriesSizes.xl}px) {
                flex-direction: row;

                p {
                width: 60%;
                padding-right: 40px;
                margin-top: 30px;
                }
                img {
                    width: 400px;
                    margin-left: 5px;
                    margin-bottom: -40px;
                    border-radius: 10px;
                    border: 4px solid ${colors.pink};

                }
            }
        }

        .small-image-right-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;

            .copy-container {
                width: 100%;
            }
            p {
                width: 100%;
                margin-bottom: 20px;
            }
            img {
                max-width: 100%;
            }

            @media (min-width: ${mediaQueriesSizes.md}px) {
                flex-direction: row;
                justify-content: space-between;
                width: 90%;

                .copy-container {
                    width: 120%;
                    padding-right: 40px;
                }
                
                img {
                    width: 100%;
                    margin-left: 5px;

                }
            }
        }


        svg {
            margin-right: 8px;
            vertical-align: bottom;
        }

        .fb-post {
            display: flex!important;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 270px;
            @media (min-width: ${mediaQueriesSizes.lg}px) {
               width: 500px;
            }
        }

        hr {
            color: black;
            background-color: ${colors.pink};
            width: 100%;
            border-width: 0;
            height: 1px;
            margin: 15px 0;
        }
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal-content {
        max-width: 90%;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            max-width: 600px;
        }
        background-color: white;
        padding: 10px;
        position: relative;
    }

    .modal-image {
        max-width: 100%;
        height: auto;
    }

    .close {
        position: absolute;
        padding: 10px;
        top: 0;
        right: 0;
        font-size: 40px;
        cursor: pointer;
        background: white;
    }

    .image-section {
        padding: 0;

        .img-wrapper {
            width: 100%;
            overflow: hidden;

            img {
                width: 300px;
            }

        }
    }

    h2 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h2.fontSizeMobile};
        font-weight: ${typography.h2.fontWeight};
        color: ${colors.pink};
        margin:0 0 20px;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h2.fontSizeDesktop};
        }
    }

    h3 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h3.fontSizeMobile};
        font-weight: ${typography.h3.fontWeight};
        color: ${colors.pink};
        margin:0 0 20px;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h3.fontSizeDesktop};
        }
    }

    h4 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h4.fontSizeMobile};
        font-weight: ${typography.h4.fontWeight};
        color: ${colors.pink};
        margin:0 0 10px;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h4.fontSizeDesktop};
        }
    }

    h5 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h5.fontSizeMobile};
        font-weight: ${typography.h5.fontWeight};
        margin:0 0 5px;
        color: ${colors.pink};
        vertical-align: bottom;
        display: flex;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h5.fontSizeDesktop};
        }

        &.warning {
            color: red;
        }

        &.display-block {
            display: block;
        }
    }

    p {
        font-family: ${typography.global.fontFamilyCopy};
        font-size: ${typography.p.fontSizeMobile};
        margin: 0;
        width: 90%;
        padding: 5px 0;
        line-height: 1.2;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.p.fontSizeDesktop};
            &.short-copy {
                max-width: 78%;
            }
        }
    }

    a {
        font-family: ${typography.global.fontFamilyCopy};
        font-size: ${typography.a.fontSizeMobile};
        font-weight: ${typography.a.fontWeight};
        color: ${colors.green};
        text-decoration: underline;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.a.fontSizeDesktop};
        }
    }

    ul {
        margin-top: 10px;
        li {
            font-family: ${typography.global.fontFamilyCopy};
            font-size: ${typography.li.fontSizeMobile};
            margin: 0;
            line-height: 1.2;

            &::marker {
                color: ${colors.pink}
            }

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: ${typography.li.fontSizeDesktop};
            }
        }
    }
`