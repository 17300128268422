import React from 'react';
import { PrimaryButton } from './styles';


const Button = ({ label, onClick, className, href, target }) => {
  return (
      <PrimaryButton className={className} onClick={onClick} href={href} target={target}>
        {label}
      </PrimaryButton>
  )
}

export default Button