import styled from "styled-components";
import { mediaQueriesSizes } from "../../assets/styles/GlobalStyles";


export const SliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        width: 65%;
        margin-bottom: 20px;

        @media (min-width: ${mediaQueriesSizes.md}px) {
            width: 85%;
        }
    }
    
`

