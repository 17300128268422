import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const ImgContainer = styled.div`
    height: 100%;
    position: relative;
    margin-top: 55px;
    display:flex;
    flex-direction: column;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        margin-top: 70px;
        flex-direction: row;
    }

    .hero {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            width: 100%%;

            img {
                width: 100%;
                height: 100%;
            }

        }

        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width: 80%;

            img {
                width: 100%;
            }

        }
    }

    .side-imgs {
        display: none;

        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width: 20%;
            display: flex;
            flex-direction: column;
            background-color: ${colors.pink};

            img {
                max-height: 210px;
                max-width: 290px;
                padding: 10px;
            }

            img:first-of-type {
                padding-top: 0;
            }
        }

        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            img {
                max-height: 230px;
                max-width: 330px;
                padding: 20px;
            }

            img:first-of-type {
                margin-top: 20px;
            }
        }
    }

    .button-container {
        margin-top: -10px;
        background: ${colors.green};
        padding: 15px;
        width: auto;

        h1 {
            font-family: ${typography.global.fontFamilyHeading};
            font-size: ${typography.h1.fontSizeMobile};
            color: ${colors.white};
            margin-bottom: 10px;
            margin-top: 0;
        }

        p {
            font-family: ${typography.global.fontFamilyCopy};
            font-size: ${typography.p.fontSizeMobile};
            color: ${colors.white};
            margin-bottom: 10px;
            margin-top: 0;
            
        }

        .button {
            display: inline-block;
            background: ${colors.red};
            padding: 15px 0;
            width: 100%;
            text-align: center;
            border: 2px solid ${colors.red};
            cursor: pointer;
            font-size: ${typography.button.fontSizeMobile};
            font-weight: ${typography.button.fontWeight};
            font-family: ${typography.global.fontFamilyCopy};
            color: ${colors.white};
            margin: 10px 0;
            text-decoration: none;

            &:hover {
                background-color: ${colors.pink};
                transition: 0.3s;
            }
        }

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            position: absolute;
            bottom: 0;
            left: 0;
            border-radius: 0 20px 0 0;
            margin-top: 0;
            max-width: 380px;
            padding: 30px;

            h1 {
                font-size: ${typography.h1.fontSizeDesktop};
                margin-bottom: 10px;
            }

            p {
                font-family: ${typography.global.fontFamilyCopy};
                font-size: ${typography.p.fontSizeDesktop};
                color: ${colors.white};
                margin-bottom: 10px;
                width: 95%;
            }

            .button {
                margin-bottom: 10px;
                max-width: 80%;
            }
        }
    }

`