import React, {useState} from 'react';
import Logo from '../../assets/images/logo.png';
import Button from '../Button';
import { HeaderWrapper, MobileMenu, DesktopMenu } from './styles';
import { navLinks } from '../../data/navLinks';

const Header = () => {
    const [burgerOpenState, setBurgerOpenState] = useState(false);

    const toggleBurgerState = () => {
        setBurgerOpenState(!burgerOpenState);
    }

    const burgerStateClass = burgerOpenState === true ? 'burger nav-active' : 'burger';

    return (
        <HeaderWrapper >
            <a href="/"><img className='logo' src={Logo} alt="Penzion Ostravanka logo" /></a>
            <button className={burgerStateClass} onClick={() => {toggleBurgerState()}}>
                <div className={burgerOpenState === true ? 'line1' : ''}></div>
                <div className={burgerOpenState === true ? 'line2' : ''}></div>
                <div className={burgerOpenState === true ? 'line3' : ''}></div>
            </button>
            <MobileMenu className={burgerOpenState === true ? 'active' : ''}>
                <ul className={'active'}>
                    {navLinks.header.map((link, key) => {
                        return (
                            <li key={key}><a className={window.location.pathname === link.href ? 'active' : ''} href={link.href}>{link.label}</a></li>
                        )
                    })}
                </ul>
                <Button className={'button'} label={'Rezervace ubytování'} href={'/rezervace'}/>
            </MobileMenu>
            <DesktopMenu>
                <ul>
                    {navLinks.header.map((link, key) => {
                        return (
                            <li key={key}><a className={window.location.pathname === link.href ? 'active' : ''} href={link.href}>{link.label}</a></li>
                        )
                    })}
                </ul>
            </DesktopMenu>
        </HeaderWrapper>
    )
}

export default Header