import React from 'react';
import Button from '../Button';
import { ImgContainer } from './styles';

const HeroBlockOtherPages = ({ imageSrc1, imageAlt1, heading }) => {
    return (
        <ImgContainer>
            <div className='hero-img-wrapper'>
                <img src={imageSrc1} alt={imageAlt1} />
            </div>
            <div className='button-container'>
                <h1>{heading}</h1>
                <Button className={'button'} label={'Rezervace ubytování'} href={'/rezervace'}/>
            </div>
            
        </ImgContainer>
    )
}

export default HeroBlockOtherPages