import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import Table from '../../components/Table';
import Img1 from '../../assets/images/zahrada6.webp';

const PriceList = () => {
  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Img1} imageAlt1={'Lavička opřená o zeď penzionu Ostravanka s výhledem do upravené zahrady se záhony.'}
            heading={"Ceník"}
            />
        <PageContainer>
          <section>
            <h2>Ceník 2023</h2>
            <h5 className='display-block'>TOP TERMÍNY: 29.3.2024 - 1.4.2024, 5.7.2024 - 7.7.2024 - <strong> Ubytování min. na 2 noci</strong></h5>
            <Table>
              <tr>
                <th>Typ pokoje/služby</th>
                <th>Mimosezóna</th>
                <th>Letní sezońa (1.6.2024 - 30.9.2024),<br/>sváteční TOP termíny</th>
              </tr>
              <tr>
                <td>1 - lůžkový</td>
                <td>740,- Kč</td>
                <td>760,- Kč</td>
              </tr>
              <tr>
                <td>2 - lůžkový</td>
                <td>900,- Kč/1 osoba<br/>
                    1.180,- Kč/2 osoby
                </td>
                <td>900,-Kč/1 osoba<br/>
                    1260,- Kč/2 osoby
                </td>
              </tr>
              <tr>
                <td>3 - lůžkový</td>
                <td>900,- Kč/1 osoba<br/>
                1.180,- Kč/2 osoby<br/>
                1.480,- Kč/3 osoby<br/>
                </td>
                <td>900,- Kč/1 osoba<br/>
                1260,- Kč/2 osoby<br/>
                1550,- Kč/3 osoby<br/>
                </td>
              </tr>
              <tr>
                <td>Studio s kuchyňským koutem<br/>(dvoulůžko + rozkládací pohovka vhodná pro děti 150x200)</td>
                <td>1.200,- Kč/1 osoba<br/>
                1.410,-Kč/2 osoby<br/>
                1.610,- Kč/3 osoby<br/>
                1.710,- Kč/4 osoby
                </td>
                <td>1200,- Kč/1 osoba<br/>
                1490,- Kč/2 osoby<br/>
                1690,- Kč/3 osoby<br/>
                1790,- Kč/4 osoby
                </td>
              </tr>
              <tr>
                <td>Dětská postýlka včetně lůžkovin</td>
                <td>300,-Kč/ jednorázově</td>
                <td>300,- Kč/ jednorázově</td>
              </tr> 
              <tr>
                <td>Privátní wellness pro hosty penzionu</td>
                <td>780,- Kč/2 osoby,<br/>
                každá další osoba  200,-Kč,<br/>
                max. 4 osoby
                </td>
                <td>780,- Kč/2 osoby,<br/>
                každá další osoba  200,-Kč,<br/>
                max. 4 osoby
                </td>
              </tr>
              <tr>
                <td>Servírovaná snídaně na objednání<br/>(obložený talíř s výběrem nápoje, pečivo)</td>
                <td>130,- Kč/ks</td>
                <td>130,- Kč/ks</td>
              </tr>
              <tr>
                <td>Ubytovací poplatek</td>
                <td>30,- Kč/den</td>
                <td>30,- Kč/den</td>
              </tr>
              <tr>
                <td>Elektrokolo - příplatek za dobíjení</td>
                <td>10,-Kč/den</td>
                <td>10,-Kč/den</td>
              </tr>
            </Table>
          </section>

          <section>
            <h2>Platební podmínky</h2>
            <ul>
              <li><strong>Cena je za pokoj/noc</strong> - bez místních poplatků a snídaní.</li>
              <li>Platba při nástupu na ubytování <strong>hotově/QR kódem</strong> nebo předem na <strong>fakturu</strong>. <strong>Platební karty nejsou akceptovány</strong>.</li>
              <li>Dítě do 2 let bez nároku na lůžko zdarma.</li>
              <li>Dle Zákona č. 278/2019 Sb. a místní Vyhlášky č. 1/2019 platí ubytovací poplatek všechny osoby ve věku od 18 let.</li>
              <li>Při pobytu na více jak 3 noci, možné získat slevu: 3 - 7% dle délky pobytu.</li>
              <li>Slevy platí POUZE pro přímou rezervaci = přes náš web, e-mailem, telefonicky, osobně.</li>
            </ul>
          </section>

          <section>
            <h2>Stornopodmínky</h2>
            <p>Pokud z vážných důvodů nemůžete nastoupit na objednaný a pobyt v penzionu, dejte prosím včas vědět e-mailem nebo telefonicky.<br/>Při zrušení pobytu je klientovi nabídnut náhradní termín.</p>
            <p>V případě zrušení celého pobytu se storno poplatky počítají z ceny objednaného ubytování (bez poplatků a snídaní) takto:</p>
            <Table>
              <tr>
                <th colSpan={'2'}>Počet dnů před nástupem</th>
              </tr>
              <tr>
                <td>7 dnů</td>
                <td>- 30%</td>
              </tr>
              <tr>
                <td>3 dny</td>
                <td>- 50%</td>
              </tr>
              <tr>
                <td>později/nedojezd</td>
                <td>- 100%</td>
              </tr>
            </Table>
            <p>V případě přeplatku bude částka vrácena zpět na původní účet nejpozději do 10 dnů.<br/>Děkujeme za pochopení.</p>
          </section>

          <section>
            <h2>Důležité informace</h2>
            <p>DODRŽUJEME PRAVIDLA GDPR<br/><br/>
              V případě, že dojde mezi námi a spotřebitelem ke vzniku spotřebitelského sporu z kupní smlouvy nebo ze smlouvy o poskytování služeb, který se nepodaří vyřešit vzájemnou dohodou, může spotřebitel podat návrh na mimosoudní řešení takového sporu určenému subjektu mimosoudního řešení spotřebitelských sporů, kterým je:<br/><br/>
              Ústřední inspektorát – oddělení ADR<br/>
              Štěpánská 15<br/>
              120 00 Praha 2<br/>
              Email: <a href="mailto:adr@coi.cz" target='_blank' rel="noreferrer">adr@coi.cz</a><br/>
              Web: <a href="https://www.adr.coi.cz" target='_blank' rel="noreferrer">adr.coi.cz</a><br/><br/>
              Spotřebitel může využít rovněž platformu pro řešení sporů online, která je zřízena Evropskou komisí na adrese <a href="http://ec.europa.eu/consumers/odr/" target='_blank' rel="noreferrer">http://ec.europa.eu/consumers/odr/</a>.<br/><br/>
              Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u správce daně on-line; v případě technického výpadku pak nejpozději do 48 hodin.</p>
          </section>
          
        </PageContainer>
        <Footer />
    </>
  )
}

export default PriceList