import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import Table from '../../components/Table';

import Pokoj1 from '../../assets/images/1luzko_3.webp';
import Pokoj2 from '../../assets/images/1luzko_4.webp';
import Pokoj3 from '../../assets/images/1luzko-koupelna.webp';
import Pokoj4 from '../../assets/images/2-luzkovy-4.webp';
import Pokoj5 from '../../assets/images/2-luzkovy-6.webp';
import Pokoj6 from '../../assets/images/3-luzkovy.webp';
import Pokoj7 from '../../assets/images/3luzka-pristylka.webp';
import Pokoj8 from '../../assets/images/3luzka-koupelna.webp';
import Pokoj9 from '../../assets/images/studio.webp';
import Pokoj10 from '../../assets/images/studio-KK.webp';
import Pokoj11 from '../../assets/images/studio-koupelna.webp';
import Pokoj12 from '../../assets/images/2-luzkovy-7.webp';
import Pokoj13 from '../../assets/images/2-luzkovy-5.webp';
import Img1 from '../../assets/images/recepce5.webp';

const Accommodation = () => {

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  
  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Img1} imageAlt1={'Fotka penzionu Ostravanka'}
            heading={"Ubytování"}
            />
        <PageContainer>
          <section>
            <p>Kapacita max. 17 lůžek (vč. přistýlek).<br/>Všechny pokoje jsou vybavené sprchou, umývadlem + WC.</p>
            <p>Další informace o cenách ubytování naleznete v sekci <a href="/cenik">Ceník</a>.</p>
            <Table>
              <tr>
                <th colSpan={2}>Typ pokoje</th>
              </tr>
              <tr>
                <td><strong>1-lůžkový pokoj</strong><br/>některý s možností dvoulůžka (170 x 200 cm)</td>
                <td>
                  <img src={Pokoj1} alt="Jednolůžkový pokoj, kde postel je u zdi, která má tapetu připomínající cihlovou zeď a zády k oku. Na druhé straně pokoje je stolek se židlí." onClick={() => openModal(Pokoj1)} />
                  <img src={Pokoj2} alt="Jednolůžkový okoj se záběrem na postel u zdi, která má tapetu připomínající cihlovou zeď." onClick={() => openModal(Pokoj2)} />
                  <img src={Pokoj3} alt="Koupelna se sprchovým koutem, umyvadlem a zrcadlem nad umyvadlem." onClick={() => openModal(Pokoj3)} />
                </td>
              </tr>
              <tr>
                <td><strong>2-lůžkový pokoj</strong><br/>manželské dvoulůžko, některé s možností twin = oddělená lůžka</td>
                <td>
                  <img src={Pokoj4} alt="Dvoulůžková postel u zdi, velké okno po levé straně postele s červenými závěsy. Naproti posteli stolek se židlí." onClick={() => openModal(Pokoj4)} />
                  <img src={Pokoj5} alt="Dvoulůžková postel u zdi, velké okno po levé straně postele s červenými závěsy. Naproti posteli stolek se židlí." onClick={() => openModal(Pokoj5)} />
                  <img src={Pokoj13} alt="Dvoulůžková postel u zdi, velké okno po levé straně postele s červenými závěsy. Naproti posteli stolek se židlí." onClick={() => openModal(Pokoj13)} />
                  <img src={Pokoj12} alt="Dvoulůžková postel u zdi, velké okno po levé straně postele s červenými závěsy. Naproti posteli stolek se židlí." onClick={() => openModal(Pokoj12)} />
                </td>
              </tr>
              <tr>
                <td><strong>3-lůžkový pokoj</strong><br/>manželské dvoulůžko + samostatné jednolůžko</td>
                <td>
                  <img src={Pokoj6} alt="Záběr na celý třílůžkový pokoj, dvoulůžková postel u zdi, vedle postele je skříň, naproti posteli je rozkládací pohovka a vedle ní stolek se židlemi. Po pravé straně postele je velké okno." onClick={() => openModal(Pokoj6)} />
                  <img src={Pokoj7} alt="Záběr na rozkládací pohovku ve složeném stavu." onClick={() => openModal(Pokoj7)} />
                  <img src={Pokoj8} alt="Velká koupelna se sprchovým koutem, záchodem, umyvadlem a zrcadlem nad umyvadlem." onClick={() => openModal(Pokoj8)} />
                </td>
              </tr>
              <tr>
                <td><strong>Studio</strong><br/>dvoulůžko + rozkládací pohovka 150x190 + kuchyňský kout</td>
                <td>
                  <img src={Pokoj9} alt="Záběr na postel u zdi ve studiu. Vedle postele je velké okno." onClick={() => openModal(Pokoj9)} />
                  <img src={Pokoj10} alt="Záběr na pohovku se stolkem a vedle kuchyňský kout se snídaňovým barem." onClick={() => openModal(Pokoj10)} />
                  <img src={Pokoj11} alt="Záběr na sprchový kout a umyvadlo se zrcadlem." onClick={() => openModal(Pokoj11)} />
                </td>
              </tr>
            </Table>
          </section>

          {modalOpen && (
            <div className="modal" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={closeModal}>&times;</span>
                <img src={selectedImage} alt="Enlarged" className="modal-image" />
              </div>
            </div>
          )}

          <section>
            <h2>Důležité informace</h2>
            <p>
              <strong>Nástup na ubytování (check-in):</strong> 14 - 18 hod. (dále pouze po dohodě).<br/>
              <strong>Uvolnění pokoje (check-out):</strong> do 10.00 hod.
            </p>
            <p>Objekt je <strong>nekuřácký a není možno ubytovat domácí mazlíčky</strong>.</p>
            <p>WIFI připojení v celém objektu ZDARMA. </p>
            <p>Parkování u objektu je pouze pro osobní auta. ZDARMA.</p>
            <p>Další informace o službách, které nabízíme, naleznete v sekci <a href="/sluzby">Wellness a služby</a>.</p>
            <p><strong>Prosíme, respektujte chod rodinného penzionu a dodržujte uvedené časy příjezdu i odjezdu ke spokojenosti vás i nás 🙂</strong></p>
          </section>
        </PageContainer>
        <Footer />
    </>
  )
}

export default Accommodation;
