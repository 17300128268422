import { createBrowserRouter } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Accommodation from "./pages/Accommodation";
import Contact from "./pages/Contact";
import Entertainment from "./pages/Entertainment";
import FormPage from "./pages/FormPage";
import InterestingLinks from "./pages/InterestingLinks";
import Photogallery from "./pages/Photogallery";
import PriceList from "./pages/PriceList";
import Wellness from "./pages/Wellness";
import ThankYou from "./pages/ThankYou";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/ubytovani",
    element: <Accommodation />,
  },
  {
    path: "/sluzby",
    element: <Wellness />,
  },
  {
    path: "/fotogalerie",
    element: <Photogallery />,
  },
  {
    path: "/rekreacezabava",
    element: <Entertainment />,
  },
  {
    path: "/kontakty",
    element: <Contact />,
  },
  {
    path: "/cenik",
    element: <PriceList />,
  },
  {
    path: "/zajimaveodkazy",
    element: <InterestingLinks />,
  },
  {
    path: "/rezervace",
    element: <FormPage />,
  },
  {
    path: "/poptavkaodeslana",
    element: <ThankYou />,
  }
]);

function App() {
  return (
    <>
        <Homepage />
        <Accommodation />
        <Wellness />
        <Entertainment />
        <Photogallery />
        <PriceList />
        <InterestingLinks />
        <Contact />
        <FormPage />
    </>
    
  );
}

export default App;
