import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Container = styled.div`
    background-color: ${colors.green};
    padding: 20px;
    display: grid;
    align-items: center;
    grid-template-areas: 
    "button button"
    "navLinks navLinks"
    "hr hr"
    "iconWrapper iconWrapper";
    grid-row-gap: 20px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
        grid-template-areas:
        "navLinks button"
        "hr hr"
        "iconWrapper iconWrapper";
    }

    .button {
        background: ${colors.red};
        grid-area: button;
        text-align: center;
        padding: 15px 0;
        border: 2px solid ${colors.red};
        cursor: pointer;
        font-size: ${typography.button.fontSizeMobile};
        font-weight: ${typography.button.fontWeight};
        font-family: ${typography.global.fontFamilyCopy};
        color: ${colors.white};
        margin: 15px 0 10px;
        text-decoration: none;

        &:hover {
            background-color: ${colors.pink};
            transition: 0.3s;
        }

        @media (min-width: ${mediaQueriesSizes.md}px) {
            width: 290px;
            margin: 0;
            justify-self: right;
        }
    }

    hr {
        color: white;
        background-color: white;
        width: 100%;
        grid-area: hr;
        border-width: 0;
        height: 1px;
        margin: 0;
    }
`

export const NavWrapper = styled.div`
    grid-area: navLinks;
    width: 100%;
`

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 140px;
    flex-wrap: wrap;

    li {
        padding-bottom: 5px;
    }

    a {
        font-family: ${typography.global.fontFamilyCopy};
        color: ${colors.white};
        font-size: ${typography.a.fontSizeMobile};
        font-weight: ${typography.a.fontWeight};
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            transition: 0.3s;
        }
        &.active {
            text-decoration: underline;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        flex-direction: row;
        max-width: 85%;
        height: 100px;
        align-items: center;

        li {
            padding-right: 20px;
        }

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        max-width: 100%;
    }
`

export const IconsWrapper = styled.div`
    grid-area: iconWrapper;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    img {
        max-width: 150px;
        margin-bottom: 20px;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
        flex-direction: row;
        overflow: hidden;
        justify-content: space-between;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        img {
            max-width: 200px;
        }
    }
`

export const IframeWrapper = styled.div`
    line-height: 1px;
    margin: 0;
    padding: 0;
    font-family: arial;
    text-align: center;
    font-size: 12px;
    width: 290px;

    iframe {
        border-right: 1px solid rgb(210, 209, 205);
        border-left: 1px solid rgb(210, 209, 205);
        border-image: initial;
        border-top: none;
        border-bottom: none;
        margin-top: -1px;
        height: 266px;
    }

    .container-1 {
        height: 42px;
        width: 290px;
        background: url(//i0.cz/najisto/common/img/widget/sprite-big.png) 0 0 no-repeat;
        font-size: 14px;
        font-weight: bold;
        color: #707070;
        line-height: 45px;
        overflow: hidden;

        span {
            float: left;
            margin-left: 11px;
            margin-right: 8px;
        }

        a {
            float: left;
            text-indent: -999px;
            width: 74px;
            height: 16px;
            margin-top: 15px;
            background: url(//i0.cz/najisto/common/img/widget/logo-najisto.png) 0 0 no-repeat;
            text-decoration: none;
            border: none;
        }
    }

    .container-2 {
        height: 20px;
        width: 290px;
        background: url(//i0.cz/najisto/common/img/widget/w-b-b.png) 0 0 no-repeat;
    }
`