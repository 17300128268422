import React, { useRef, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import Table from '../../components/Table';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Wellness1 from '../../assets/images/wellness-vstup.webp';
import Wellness2 from '../../assets/images/IMG_7814.webp';

gsap.registerPlugin(ScrollTrigger);

const Wellness = () => {

  useEffect(() => {

    const timer = setTimeout(() => {
      gsap.from(elementRefs, {
        scrollTrigger: {
          trigger: elementRefs[0],
          start: "top 80%",
          toggleActions: "play none none none",
          ease: "none",
        },
        opacity: 0,
        duration: 1.8,
        ease: "none",
        stagger: 0.6,
      });
    }, 500)

    return () => clearTimeout(timer)
    
  }, []);

  const triggerRef = useRef();
  const elementRefs = [];

  const setRef = (ref) => {
    elementRefs.push(ref);
  }

  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Wellness1} imageAlt1={'Fotka vstupní cedule na dveřích do privátního wellnessu s nápisem Relax Hydrotherapy'}
            heading={"Wellness a služby"}
            />
        <PageContainer>
          <section>
            <h2>Privátní wellness</h2>
            <p>Pro chvíle relaxu nabízíme hydromasážní vanu pro 1 - 2 osoby, finskou saunu či přístrojovou masáž chodidel.<br/>
            <strong>Maximální kapacita wellnessu je 4 osoby.</strong><br/><br/>
            <strong>Objednávky min. 2 hodiny předem na tel. 723 963 905. Poslední vstup ve 20.00 hod.</strong>
            </p>
            <br/>
            <h5 style={{marginBottom: '-15px', marginTop: '20px'}}><strong>Ceník procedur</strong></h5>
            <Table>
              <tr>
                <th>Hydromasážní vana + finská sauna (max 4 osoby)</th>
                <th>Host</th>
                <th>Návštěvník</th>
              </tr>
              <tr>
                <td><strong>1 osoba</strong></td>
                <td>650,-Kč/120 min.</td>
                <td>750,-Kč/120 min.</td>
              </tr>
              <tr>
                <td><strong>2 osoby</strong></td>
                <td>780,-Kč/120 min.</td>
                <td>880,-Kč/120 min.</td>
              </tr>
              <tr>
                <td><strong>Každá další osoba</strong></td>
                <td>+ 200,-Kč</td>
                <td>+ 200,-Kč</td>
              </tr>
              <tr>
                <td><strong>Každých započatých 15 min.</strong></td>
                <td>+ 50,-Kč</td>
                <td>+ 50,-Kč</td>
              </tr>

              <tr>
                <th>Samostatná finská sauna (max 4 osoby)</th>
                <th>Host</th>
                <th>Návštěvník</th>
              </tr>
              <tr>
                <td><strong>1 osoba</strong></td>
                <td>420,-Kč/90 min.</td>
                <td>460,-Kč/90 min.</td>
              </tr>
              <tr>
                <td><strong>2 osoby</strong></td>
                <td>560,-Kč/90 min.</td>
                <td>600,-Kč/90 min.</td>
              </tr>
              <tr>
                <td><strong>Každá další osoba</strong></td>
                <td>+ 150,-Kč</td>
                <td>+ 150,-Kč</td>
              </tr>

              <tr>
                <th>Masáž chodidel (přístrojová)</th>
                <th>Host</th>
                <th>Návštěvník</th>
              </tr>
              <tr>
                <td><strong>1 osoba</strong></td>
                <td>150,-Kč/15 min.</td>
                <td>150,-Kč/15 min.</td>
              </tr>
            </Table>
            <div className='image-right-wrapper'>
              <p>V ceně je osuška, prostěradlo, nápoj, (župan za příplatek).<br/><br/>
            V našem relaxu jste pouze vy, po každém cyklu se vana, sauna i celý prostor řádně dezinfikuje.<br/><br/>
            Informace o ubytování naleznete v sekci <a href="/ubytovani">Ubytování</a>.
            </p>
              <img src={Wellness2} alt="Privátní wellnes se záběrem na hydromasážní vanu, finskou saunu a relaxační posezení v příjemném nastavitelném osvětlení." />
            </div>
            
            
          </section>

          <section>
            <h2>Služby penzionu</h2>
            <div ref={triggerRef} className='div-wrapper-grid'>
              <div ref={setRef} id='kitchen'>
                <h5><strong>Kuchyňka</strong></h5>
                <ul>
                  <li>společná pro vlastní vaření</li>
                  <li>plně vybavená</li>
                  <li>lednice</li>
                  <li>sporák (trouba)</li>
                  <li>mikrovlnná trouba</li>
                  <li>varná konvice</li>
                  <li>nádobí</li>
                </ul>
              </div>
              <div ref={setRef} id='comunal'>
                <h5><strong>Společné zázemí, zahrada</strong></h5>
                <ul>
                  <li>jídelna s pohodlným sezením</li>
                  <li>hrací stůl</li>
                  <li>stolní fotbálek</li>
                  <li>knihy a společenské hry</li>
                  <li>samoobslužný minibar s výběrem nápojů</li>
                  <li>zahradní altán s osvětleným posezením </li>
                  <li>možnost grilování na zahradě</li>
                </ul>
              </div>
              <div ref={setRef} id='other'>
                <h5><strong>Ostatní</strong></h5>
                <ul>
                  <li>parkování zdarma u objektu <br/>(nehlídané) pouze pro osobní auta</li>
                  <li>zapůjčení dětské postýlky (příplatek)</li>
                  <li>wifi připojení zdarma</li>
                  <li>možnost úschovy kol zdarma</li>
                  <li>dobíjení elektrokol za příplatek</li>
                  <li>možnost objednání snídaní</li>
                </ul>
              </div>
            <h4><i><strong>Připravíme Vám dobrou kávu či čaj k vychutnání ve stylové verandě či na zahradě</strong></i></h4>
            </div>
          </section>
        </PageContainer>
        <Footer />
    </>
  )
}

export default Wellness