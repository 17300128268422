import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const HeaderWrapper = styled.nav`
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: ${colors.pink};
    box-shadow: 5px 3px 7px 1px rgb(0 0 0 / 30%);

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        height: 70px;
    }

    .logo {
        height: 49px;
        width: auto;
        background-image: url(Logo);
        background-size: cover;
        background-position: center;
        margin-left: 10px;
        margin-top: 4px;
        justify-self: center;

        @media (min-width: ${mediaQueriesSizes.xl}px) {
            height: 60px;
        }
    }

    .burger {
        z-index: 100000;
        margin-right: 10px;
        cursor: pointer;
        border: none;
        background-color: ${colors.pink};

        @media (min-width: ${mediaQueriesSizes.xl}px) {
            display: none;
        }
        div {
            width: 36px;
            height: 3px;
            background-color: ${colors.white};
            margin: 7px;
            transition: all 0.3s ease;
        }
        
        .line1 {
        transform: rotate(-45deg) translate(-6px, 6px);
        }

        .line2 {
            opacity: 0;
        }

        .line3 {
            transform: rotate(45deg) translate(-7px, -7px);
        }
    }

`

export const MobileMenu = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 10;
    right: -100%;
    transition: 0.5s;
    background: ${colors.pink};

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        display: none;
    }

    &.active,
    &:focus-within {
        right: 0;
    }

    ul {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            font-family: ${typography.global.fontFamilyCopy};
            font-size: ${typography.li.fontSizeMobile};
            font-weight: ${typography.li.fontWeight};
            font-style: normal;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            margin-left: 0;

            a {
                padding: 15px 0;
                display: block;
                text-decoration: none;
                color: ${colors.white};
                font-weight: 600;

                &:hover {
                    background: ${colors.red};
                    transition: 0.3s;
                }

                &:after {
                    color: ${colors.white};
                }
            }
        }
    }

    .button {
        background: ${colors.red};
        grid-area: button;
        text-align: center;
        padding: 15px 45px;
        border: 2px solid ${colors.red};
        cursor: pointer;
        font-size: ${typography.button.fontSizeMobile};
        font-weight: ${typography.button.fontWeight};
        font-family: ${typography.global.fontFamilyCopy};
        color: ${colors.white};
        margin: 15px 0 10px;
        text-decoration: none;

        &:hover {
            background-color: ${colors.pink};
            transition: 0.3s;
        }

        @media (min-width: ${mediaQueriesSizes.md}px) {
            width: 290px;
            margin: 0;
            justify-self: right;
        }
    }
`

export const DesktopMenu = styled.div`
    display: none;
    
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        display: flex;
        align-items: center;

        ul {
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: inline-block;
                font-family: ${typography.global.fontFamilyCopy};
                font-weight: ${typography.li.fontWeight};
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                

            a {
                padding: 14px 15px;
                display: block;
                text-decoration: none;
                color: ${colors.white};

                &:hover {
                    background: ${colors.red};
                    transition: 0.3s;
                }

                &:after {
                    color: ${colors.white};
                }
                &.active {
                    background: ${colors.red};
                }

                @media (min-width: ${mediaQueriesSizes.xl}px) {
                    padding: 23px 25px;
                }
            }
        }

    }

}
`