import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlock from '../../components/HeroBlock';

import Zahrada4 from '../../assets/images/zahrada4.webp';
import Okoli1 from '../../assets/images/okoli2.webp';
import Okoli2 from '../../assets/images/okoli6.webp';
import Wellness1 from '../../assets/images/sauna.webp';
import Wellness2 from '../../assets/images/IMG_7822.webp';
import Wellness3 from '../../assets/images/IMG_7823.webp';
import Wellness4 from '../../assets/images/IMG_7812.webp';
import Recepce1 from '../../assets/images/recepce1.webp';
import HeroImage from '../../assets/images/hero.png';

import { ContentContainer } from './styles';


const Homepage = () => {

    return (
        <>
            <Header />
            <HeroBlock 
            imageSrc1={HeroImage} imageAlt1={'Fotka budovy penzionu Ostravanka.'}
            imageSrc2={Wellness1} imageAlt2={'Finská sauna se světlým dřevem vyfocená zevnitř.'}
            imageSrc3={Recepce1} imageAlt3={'Recepce penzionu Ostravanka.'}
            imageSrc4={Wellness4} imageAlt4={'Privátní wellnes se záběrem na relaxační posezení a dekoraci.'}
            heading={"PENZION OSTRAVANKA"}
            />
            <ContentContainer>
                <div className='content-copy-1'>
                    <h2>O nás</h2>
                    <p><strong>Rodinný penzion Ostravanka**superior</strong> s dlouhou tradicí i historií zve k příjemnému ubytování v klidné lázeňské obci Teplice nad Bečvou, ležící na levém břehu stejnojmenné řeky v kopcovitém terénu.<br/><br/>Lázně Teplice nad Bečvou přímo sousedí s městem Hranice na Moravě. Od našeho penzionu obklopeného zelení se otevírá překrásný pohled na panorama Beskyd s Radhoštěm a Lysou horou a panorama Hostýnských vrchů.</p>
                </div>
                <div className='content-images-1'>
                    <div>
                        <img src={Okoli1} alt="Lázně Hranice na Morave focené v noci." />
                    </div>
                    <div>
                        <img src={Okoli2} alt="Fotka hradu Helfštýn." />
                    </div>
                </div>
                <div className='content-copy-2'>
                    <h2>Ubytování</h2>
                    <p>Penzion nabízí příjemně vybavené 1 - 3lůžkové pokoje, studio s KK, společnou kuchyňku pro vlastní vaření či snídaně na objednávku. Součástí je také zahrada s venkovním posezením a soukromé parkoviště.<br/><br/>Oblibu u našich hostů si získal prostor recepce penzionu navozující atmosféru kavárny, kde je možno si objednat příjemnou snídani, nebo si zde můžete vychutnat odpolední kávu s výhledem do zahrady.</p>
                </div>
                <div className='content-images-2'>
                    <div>
                        <img src={Zahrada4} alt="Fotka venkovního posezení u penzionu Ostravanka." />
                    </div>
                    <div>
                        <img src={Recepce1} alt="Recepce penzionu Ostravanka." />
                    </div>
                </div>
                <div className='content-copy-3'>
                    <h2>Relax</h2>
                    <p>Ať už máte v plánu služební cestu, cykloturistiku po okolí či návštěvu příbuzných v lázních Teplice nad Bečvou, penzion Ostravanka skýtá příjemné útočiště pro vaši cestu.<br/><br/>NOVĚ je hostům k dispozici <strong>privátní wellness</strong> (hydromasážní vana + finská sauna).<br/><br/><strong><i>Na Vaši návštěvu se těší rodina Hlaváčkova</i></strong></p>
                </div>
                <div className='content-images-3'>
                    <div>
                        <img src={Wellness2} alt="Privátní wellnes se záběrem na relaxační posezení a dekoraci." />
                    </div>
                    <div>
                        <img src={Wellness3} alt="Privátní wellnes se záběrem na hydromasážní vanu a finskou saunu." />
                    </div>
                </div>
            </ContentContainer>
            <Footer />
        </>
    )
}

export default Homepage