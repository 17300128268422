// Render Prop
import React, { useRef, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import emailjs from '@emailjs/browser';
import { Navigate } from 'react-router-dom';

import {
    Label,
    Input,
    StyledInlineErrorMessage,
    Submit,
} from "./styles";

const FormComponent = () => {
    const [allowSubmit, setAllowSubmit] = useState(false);

    const form = useRef();

    // const today = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
    const today = '2024-02-26'
    const phoneRegex = "^[+]?[- ()0-9]{8,}$"

    return (
        <Formik
            initialValues={{ 
                name: '', 
                email: '', 
                phone: '', 
                arrivalDate: '', 
                departureDate: '', 
                roomType: 'Typ pokoje nebyl vybrán', 
                roomCount: 0, 
                breakfast: 'Ano', 
                city: '', 
                adults: 0, 
                kids: 0, 
                kidsBed: 'Ne', 
                notes: '',
            }}
            validate={values => {
                const errors = {};

                if (!values.email) {
                errors.email = 'Údaj je povinný';
                } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                errors.email = 'E-mail je ve špatném formátu';
                }
                if (!values.name) {
                    errors.name = 'Údaj je povinný';
                }
                if (!values.phone) {
                    errors.phone = 'Údaj je povinný';
                } else if(values.phone !== '' &&!values.phone.match(new RegExp(phoneRegex, 'i'))) {
                    errors.phone = 'Telefon je ve špatném formátu';
                }
                if (!values.arrivalDate) {
                    errors.arrivalDate = 'Údaj je povinný';
                }
                if (!values.departureDate) {
                    errors.departureDate = 'Údaj je povinný';
                }
                if (values.roomCount < 0) {
                    errors.roomCount = 'Údaj je neplatný';
                } else if (values.roomCount === 0) {
                    errors.roomCount = 'Údaj je povinný';
                }
                if (values.adults < 0) {
                    errors.adults = 'Údaj je neplatný';
                } else if (values.adults === 0) {
                    errors.adults = 'Údaj je povinný';
                }
                if (values.kids < 0) {
                    errors.kids = 'Údaj je neplatný';
                } else if (values.kids === '') {
                    errors.kids = 'Údaj je povinný';
                }
                if (!values.city) {
                    errors.city = 'Údaj je povinný';
                }

                if (Object.keys(errors).length === 0) {
                    setAllowSubmit(true);
                } else {
                    setAllowSubmit(false);
                }

                return errors;
            }}
            onSubmit={(values) => {
                emailjs.sendForm(
                    'service_dr3kufj', 
                    'template_4n8o7i7', 
                    form.current, 
                    'Cp-Sq1B9i3qiRBEnK'
                    )
                .then((result) => {
                    console.log(result.text);
                    setAllowSubmit(false)
                }, (error) => {
                    console.log(error.text);
                });


            }}

            >
            {({ 
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                isSubmitting,
                isValidating,
            }) => {
                return (
                    <>
                    {isSubmitting && (
                        <Navigate to={'/poptavkaodeslana'} replace={true}/>
                    )}

                    <Form ref={form} onSubmit={handleSubmit}>

                        <Label htmlFor="name">
                        Jméno a příjmení*
                        <Input
                            type="text"
                            name="name"
                            autoCorrect="off"
                            autoComplete="name"
                            placeholder="Jméno a příjmení"
                            error={touched.name && errors.name}
                            valid={touched.name && !errors.name}
                        />
                        </Label>
                        {errors.name && touched.name && (
                        <StyledInlineErrorMessage>
                            {errors.name}
                        </StyledInlineErrorMessage>
                        )}
                        
                        <Label htmlFor="email">
                        E-mail*
                        <Input
                            type="email"
                            name="email"
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="email"
                            placeholder="vas-email@email.com"
                            error={touched.email && errors.email}
                            valid={touched.email && !errors.email}
                        />
                        </Label>
                        <ErrorMessage name="email">
                        {msg => (
                            <StyledInlineErrorMessage>{msg}</StyledInlineErrorMessage>
                        )}
                        </ErrorMessage>
                        
                        <Label htmlFor="phone">
                        Telefon*
                        <Input
                            type="tel"
                            name="phone"
                            autoCorrect="off"
                            autoComplete="phone"
                            placeholder="123456789"
                            error={touched.phone && errors.phone}
                            valid={touched.phone && !errors.phone}
                        />
                        </Label>
                        {errors.phone && touched.phone && (
                        <StyledInlineErrorMessage>
                            {errors.phone}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="arrivalDate">
                        Datum příjezdu*
                        <Input
                            type="date"
                            name="arrivalDate"
                            autoCorrect="off"
                            autoComplete="arrivalDate"
                            placeholder=""
                            min={today}
                            error={touched.arrivalDate && errors.arrivalDate}
                            valid={touched.arrivalDate && !errors.arrivalDate}

                        />
                        </Label>
                        {errors.arrivalDate && touched.arrivalDate && (
                        <StyledInlineErrorMessage>
                            {errors.arrivalDate}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="departureDate">
                        Datum odjezdu*
                        <Input
                            type="date"
                            name="departureDate"
                            autoCorrect="off"
                            autoComplete="departureDate"
                            placeholder=""
                            min={values.arrivalDate ? values.arrivalDate : today}
                            error={touched.departureDate && errors.departureDate}
                            valid={touched.departureDate && !errors.departureDate}
                        />
                        </Label>
                        {errors.departureDate && touched.departureDate && (
                        <StyledInlineErrorMessage>
                            {errors.departureDate}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="roomType">
                        Typ pokoje*
                        <Input
                            as="select"
                            name="roomType"
                            autoCorrect="off"
                            autoComplete="roomType"
                            error={touched.roomType && errors.roomType}
                            valid={touched.roomType && !errors.roomType}
                            value={values.roomType}
                            onChange={handleChange}
                        >
                            <option value="Typ pokoje nebyl vybrán">Vyberte z možností</option>
                            <option value="1 lůžko">1 lůžko</option>
                            <option value="2 lůžka oddělená">2 lůžka oddělená</option>
                            <option value="Dvoulůžko">Dvoulůžko</option>
                            <option value="3 lůžka">3 lůžka</option>
                            <option value="Studio">Studio</option>
                        </Input>
                        </Label>
                        {errors.roomType && touched.roomType && (
                        <StyledInlineErrorMessage>
                            {errors.roomType}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="roomCount">
                        Počet pokojů*
                        <Input
                            type="number"
                            name="roomCount"
                            autoCorrect="off"
                            autoComplete="roomCount"
                            placeholder="0"
                            error={touched.roomCount && errors.roomCount}
                            valid={touched.roomCount && !errors.roomCount}
                        />
                        </Label>
                        {errors.roomCount && touched.roomCount && (
                        <StyledInlineErrorMessage>
                            {errors.roomCount}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="adults">
                        Počet dospělých*
                        <Input
                            type="number"
                            name="adults"
                            autoCorrect="off"
                            autoComplete="adults"
                            placeholder="0"
                            error={touched.adults && errors.adults}
                            valid={touched.adults && !errors.adults}
                        />
                        </Label>
                        {errors.adults && touched.adults && (
                        <StyledInlineErrorMessage>
                            {errors.adults}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="kids">
                        Počet dětí*
                        <Input
                            type="number"
                            name="kids"
                            autoCorrect="off"
                            autoComplete="kids"
                            placeholder=""
                            error={touched.kids && errors.kids}
                            valid={touched.kids && !errors.kids}
                        />
                        </Label>
                        {errors.kids && touched.kids && (
                        <StyledInlineErrorMessage>
                            {errors.kids}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="kidsBed">
                        Požaduji dětskou postýlku (rozumí se malá postýlka do 3 let)
                        <Input
                            as="select"
                            name="kidsBed"
                            autoCorrect="off"
                            autoComplete="kidsBed"
                            placeholder=""
                            error={touched.kidsBed && errors.kidsBed}
                            valid={touched.kidsBed && !errors.kidsBed}
                            onChange={handleChange}
                            >
                            <option value="Ne">Ne</option>
                            <option value="Ano">Ano</option>
                        </Input>
                        </Label>
                        {errors.kidsBed && touched.kidsBed && (
                        <StyledInlineErrorMessage>
                            {errors.kidsBed}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="breakfast">
                        Snídaně
                        <Input
                            as="select"
                            name="breakfast"
                            autoCorrect="off"
                            autoComplete="breakfast"
                            placeholder=""
                            error={touched.breakfast && errors.breakfast}
                            valid={touched.breakfast && !errors.breakfast}
                            onChange={handleChange}
                            >
                            <option value="Ano">Ano</option>
                            <option value="Ne">Ne</option>
                        </Input>
                        </Label>
                        {errors.breakfast && touched.breakfast && (
                        <StyledInlineErrorMessage>
                            {errors.breakfast}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="city">
                        Město/obec*
                        <Input
                            type="text"
                            name="city"
                            autoCorrect="off"
                            autoComplete="city"
                            placeholder=""
                            error={touched.city && errors.city}
                            valid={touched.city && !errors.city}
                        />
                        </Label>
                        {errors.city && touched.city && (
                        <StyledInlineErrorMessage>
                            {errors.city}
                        </StyledInlineErrorMessage>
                        )}

                        <Label htmlFor="notes">
                        Poznámka
                        <Input
                            as="textarea"
                            name="notes"
                            autoCorrect="off"
                            autoComplete="notes"
                            placeholder="Speciální požadavky..."
                            error={touched.notes && errors.notes}
                            valid={touched.notes && !errors.notes}
                            onChange={handleChange}
                        />
                        </Label>
                        {errors.notes && touched.notes && (
                        <StyledInlineErrorMessage>
                            {errors.notes}
                        </StyledInlineErrorMessage>
                        )}

                        <Submit type="submit" disabled={!allowSubmit}>
                            Odeslat poptávku
                        </Submit>
                    </Form>
                </>
                );
            }}
        </Formik>
    )
  
};

export default FormComponent;