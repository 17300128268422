import styled from "styled-components";
import { colors, typography, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const ImgContainer = styled.div`
    width: 100%;
    position: relative;
    margin-top: 55px;

    .hero-img-wrapper {
        width: 100%;
        max-height: 300px;
        
        img {
            width: 100%;
            max-height: 300px;
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 50px 100px;
        margin-top: 70px;


        .hero-img-wrapper {
            width: 57%;
            max-height: 500px;
            min-height: 400px;
        
            img {
                width: 100%;
                border-radius: 10px;
                border: 4px solid ${colors.pink};
                max-height: 500px;
                min-height: 400px;
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        .hero-img-wrapper {
            img {
                max-height: 570px;
                min-height: 400px;
            }
        }
    }

    .button-container {
        background: ${colors.green};
        padding: 25px 15px;
        height: auto;
        margin-top: -10px;

        h1 {
            font-family: ${typography.global.fontFamilyHeading};
            font-size: ${typography.h1.fontSizeMobile};
            color: ${colors.white};
            margin-bottom: 10px;
            margin-top: 0;
            text-align: center;
        }

        p {
            font-family: ${typography.global.fontFamilyCopy};
            font-size: ${typography.p.fontSizeMobile};
            color: ${colors.white};
            margin-bottom: 10px;
            margin-top: 0;
        }

        .button {
            display: inline-block;
            background: ${colors.red};
            padding: 15px 0;
            width: 100%;
            text-align: center;
            border: 2px solid ${colors.red};
            cursor: pointer;
            font-size: ${typography.button.fontSizeMobile};
            font-weight: ${typography.button.fontWeight};
            font-family: ${typography.global.fontFamilyCopy};
            color: ${colors.white};
            margin: 10px 0;
            text-decoration: none;

            &:hover {
                background-color: ${colors.pink};
                transition: 0.3s;
            }
        }

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            position: absolute;
            bottom: 170px;
            right: 360px;
            border-radius: 10px;
            margin-top: 0;
            min-width: 370px;
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px;

            h1 {
                font-size: ${typography.h1.fontSizeDesktop};
                margin-bottom: 10px;
            }

            p {
                font-family: ${typography.global.fontFamilyCopy};
                font-size: ${typography.p.fontSizeDesktop};
                color: ${colors.white};
                margin-bottom: 10px;
                width: 86%;
            }

            .button {
                margin-bottom: 10px;
                max-width: 80%;
            }
        }
    }

`