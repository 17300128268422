import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import ImageCarousel from '../../components/ImageCarousel';
import { SliderWrapper } from './styles';
import {pokoje, recepce, wellness, okoli} from './photos';
import Zahrada4 from '../../assets/images/zahrada15.webp';


const Photogallery = () => {
  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Zahrada4} imageAlt1={'Venkovní posezení u penzionu Ostravanka'}
            heading={"Fotogalerie"}
            />
        <PageContainer>
          <section>
            <SliderWrapper>
              <h4><strong>Pokoje</strong></h4>
              <ImageCarousel images={pokoje}/>
            </SliderWrapper>
          </section>

          <section>
            <SliderWrapper>
            <h4><strong>Společné zázemí a recepce</strong></h4>
              <ImageCarousel images={recepce}/>
            </SliderWrapper>
          </section>

          <section>
            <SliderWrapper>
            <h4><strong>Privátní wellness</strong></h4>
              <ImageCarousel images={wellness}/>
            </SliderWrapper>
          </section>

          <section>
            <SliderWrapper>
              <h4><strong>Okolí</strong></h4>
              <ImageCarousel images={okoli}/>
            </SliderWrapper>
          </section>

          {/* <section>
          <iframe src="https://www.google.com/maps/embed?pb=!4v1698578734245!6m8!1m7!1sCAoSLEFGMVFpcE5wU0pyRWhqNmZvX2lFdjRLczZ4MkVNWWtqYUpHaUItemctcmlj!2m2!1d49.531314561492!2d17.741367590035!3f80!4f0!5f0.7820865974627469" width="600" height="450" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </section> */}
          
        </PageContainer>
        <Footer />
    </>
  )
}

export default Photogallery