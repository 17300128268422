import React, { useState } from 'react';
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";

const ImageCarousel = ({ images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
    setModalOpen(false);
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const previousImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div className='image-wrapper' key={index} onClick={() => openModal(index)}>
            <img className='image' src={image.src} alt={image.alt} />
          </div>
        ))}
      </Slider>

      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content-photogallery" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="modal-image-container">
              <img src={images[selectedImageIndex].src} alt={images[selectedImageIndex].alt} className="modal-image" />
              <div className='button-container'>
                <button className="prev" onClick={previousImage}>&#10094;</button>
                <button className="next" onClick={nextImage}>&#10095;</button>
              </div>
              
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
