import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HeroBlockOtherPages from '../../components/HeroBlockOtherPages';
import PageContainer from '../../components/PageContainer';
import Icon2 from '../../assets/images/icon2.png';
import Icon3 from '../../assets/images/icon4.png';
import Img1 from '../../assets/images/hero.png';

import { IconsWrapper } from './styles';

const InterestingLinks = () => {
  return (
    <>
        <Header />
        <HeroBlockOtherPages 
            imageSrc1={Img1} imageAlt1={'Fotka budovy penzionu Ostravanka.'}
            heading={"Zajímavé odkazy"}
            />
        <PageContainer>
          <section>
            <p className='short-copy'><strong>Zdravotní Klaun</strong><br/>Zdravotní klaun je dobročinná organizace, která od roku 2001 pomáhá zejména dětským, ale i geriatrickým pacientům procházet procesem léčby s úsměvem. Kliknutím na banner přejdete na oficiální stránky.<br/><br/><i>“Rozdávat radost a smích tam, kde se jich nedostává”</i></p>
            <IconsWrapper>
              <a href="https://www.zdravotniklaun.cz/" target="_blank" rel="noreferrer">
                <img src="https://www.zdravotniklaun.cz/fileadmin/czech/Files_ke_stazeni/Jine_oficialni_dokumenty_a_loga/banner_firmy_kulaty.png" width="180"  height="180" alt="Kudyznudy.cz - nejlepší začátek výletu" />
              </a>
            </IconsWrapper>
            <p className='short-copy'><strong>Carte, Sphere</strong><br/>Slevu u těchto partnerů lze uplatnit POUZE na ubytování min. na 3 noci při předložení klubové karty.<br/>Nelze uplatnit na akční nabídky. Kliknutím na logo přejdete na oficiální stránky.</p>
            <IconsWrapper>
              <a href="https://www.carte.cz/" target='_blank' rel="noreferrer">
                <img src={Icon2} alt="Logo společnosti Carte" />
              </a>
              <a href="https://www.sphere.cz/" target='_blank' rel="noreferrer">
                <img src={Icon3} alt="Logo společnosti Sphere" />
              </a>
            </IconsWrapper>
            <p className='short-copy'><strong>Kudy z nudy</strong><br/>Tipy na zajímavé akce a události, které aktuálně probíhají. Kliknutím na banner přejdete na oficiální stránky.</p>
            <IconsWrapper>
              <a href="https://www.kudyznudy.cz/?utm_source=kzn&utm_medium=partneri_kzn&utm_campaign=banner" target="_blank" rel="noreferrer">
                <img src="https://www.kudyznudy.cz/getmedia/e258ea1e-6a92-4443-940f-fdafe8da106e/1012102023-online-bannery-hq-180x150.jpg.aspx" width="180"  height="150" alt="Kudyznudy.cz - nejlepší začátek výletu" />
              </a>
            </IconsWrapper>
            <p className='short-copy'><strong>Aktuálně v mikroregionu</strong><br/>Zajímá vás jak probíhal tříletý výzkum o Hranickém krasu? Zavítejte do hranického městského muzea, kde právě probíhá <a href="https://www.regionhranicko.cz/mas-hranicko/aktuality/Trilete-badani-o-Hranickem-krasu-zavrseno-Prohlednete-si-prezentace-z-konference-a-vyrazte-na-vystavu-1455" target='_blank' rel="noreferrer">výstava Hranice poznání</a>. Na výstavě se dozvíte k jakým poznatkům došel výzkumný tým brněnské Mendelovy univerzity.</p>
          </section>
        </PageContainer>
        <Footer />
    </>
  )
}

export default InterestingLinks