import styled from "styled-components";
import { colors, mediaQueriesSizes, typography } from "../../assets/styles/GlobalStyles";

export const ThankYouHeader = styled.div`
    background-color: ${colors.pink};
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        height: 50px;
        margin-left: 20px;
    }

    h4 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h4.fontSizeMobile};
        font-weight: ${typography.h4.fontWeight};
        color: ${colors.white};
        margin-right:20px;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h4.fontSizeDesktop};
        }
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    align-items: center;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        padding: 50px 0;
    }

    h2 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h2.fontSizeMobile};
        font-weight: ${typography.h2.fontWeight};
        color: ${colors.pink};
        margin:0 0 20px;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h2.fontSizeDesktop};
        }
    }

    h4 {
        font-family: ${typography.global.fontFamilyHeading};
        font-size: ${typography.h4.fontSizeMobile};
        font-weight: ${typography.h4.fontWeight};
        color: ${colors.pink};
        margin: 20px 0 0;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.h4.fontSizeDesktop};
            margin: 50px 0 0;

        }
    }

    div {
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-items: center;
        padding: 30px 15px;
        border: 1px solid ${colors.pink};
        border-radius: 15px;
        background-color: ${colors.lightPink};

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            font-size: ${typography.p.fontSizeDesktop};
            margin-top: 30px;
            width: 50%;
            padding: 30px;
        }

        p {
        font-family: ${typography.global.fontFamilyCopy};
        font-size: ${typography.p.fontSizeMobile};
        line-height: 1.5;
        text-align: center;

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: ${typography.p.fontSizeDesktop};
                padding: 0 30px;
            }
        }

        a {
            font-family: ${typography.global.fontFamilyCopy};
            font-size: ${typography.a.fontSizeMobile};
            font-weight: ${typography.a.fontWeight};
            color: ${colors.green};
            text-decoration: underline;
            cursor: pointer;

            @media (min-width: ${mediaQueriesSizes.lg}px) {
                font-size: ${typography.a.fontSizeDesktop};
            }

            &.home-page-link {
                font-family: ${typography.global.fontFamilyCopy};
                color: ${colors.white};
                background: ${colors.green};
                border-radius: 50px;
                padding: 20px 30px;
                text-decoration: none;
                max-width: 300px;

                &:hover {
                    background-color: ${colors.lightGreen};
                }
            }
        }
    }
`