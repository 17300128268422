import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FormComponent from '../../components/Form';
import PageContainer from '../../components/PageContainer';
import { FormContainer } from './styles';

const FormPage = () => {
  return (
    <>
        <Header />
        <br />
        <br />
        <PageContainer>
          <section>
          <h2>Poptávkový formulář pro rezervaci pobytu</h2>
          <p>Proveďte si svoji rezervaci přímo zde, e-mailem nebo telefonicky (viz <a href="/kontakty">Kontakty</a>) a vyčktejte naší odpovědi ohledně dostupnosti termínu, případně náhradního řešení.<br/>
            Pro závaznou rezervaci je zpravidla vyžadována záloha ve výši 50% ceny pobytu.<br/>
            Dbejte prosím na správné vyplnění <a href="/ubytovani">typu pokoje</a>.
          </p>
          <p><strong>Nástup na ubytování: 14 - 18 hod.</strong></p>
          <p>Další informace o ubytování naleznete <a href="/ubytovani">zde</a>.</p><br/>
          <h5 className='display-block'>TOP TERMÍNY: 29.3.2024 - 1.4.2024, 5.7.2024 - 7.7.2024 - <strong> Ubytování min. na 2 noci</strong></h5>
          </section>
          <section>
            <FormContainer>
              <FormComponent />
            </FormContainer>
          </section>
          <section>
            <h2>Důležité informace</h2>
            <p>Odesláním tohoto formuláře souhlasíte se zpracováním Vašich osobních dat pro účely rezervace.</p>
            <p>Potvrzením rezervace ze strany ubytovatele e-mailem nebo telefonicky dochází k uzavření Ubytovací smlouvy.</p>
            <p>Odesláním rezervace potvrzujete seznámení se Stornopodmínkami viz <a href="/cenik">Ceník</a></p>
            <p>Pro jakékoli dotazy nás neváhejte kontaktovat telefonicky viz <a href="/kontakty">Kontakty</a>.</p>
            <p>Děkujeme za Váš zájem a budeme se snažit vyhovět všem Vašim požadavkům. </p>
            <p>Váš penzion Ostravanka :-)</p>
          </section>
        </PageContainer>
        <Footer />
    </>
  )
}

export default FormPage